export const serviceTypes = [
  "с проживанием",
  "без проживания",
  "в больницу",
  "почасовая оплата",
  "недорого",
  "с деменцией",
  "без посредников",
  "на ночь",
  "на дом",
];
